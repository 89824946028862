import React, { useRef, useState, useEffect } from 'react';
import { Canvas, useLoader, useFrame } from '@react-three/fiber';
import { OrbitControls, Plane } from '@react-three/drei';
import * as THREE from 'three';
import { useGesture } from '@use-gesture/react';
import axios from 'axios';  // Import axios for HTTP requests

function RotatingPhoto({ imageUrl, scale, glowColor }) {
  const texture = useLoader(THREE.TextureLoader, imageUrl);
  const meshRef = useRef();

  useFrame(() => {
    if (meshRef.current) {
      meshRef.current.scale.set(scale, scale, scale);
    }
  });

  return (
    <>
      {/* Glow Plane */}
      <Plane args={[5.2, 5.2]} position={[0, 0, -0.01]}>
        <meshBasicMaterial
          attach="material"
          color={glowColor}
          transparent
          opacity={0.5} // Adjust the glow's opacity
        />
      </Plane>

      {/* Main Image Plane */}
      <Plane ref={meshRef} args={[5, 5]}>
        <meshBasicMaterial
          attach="material"
          map={texture}
        />
      </Plane>
    </>
  );
}

export default function RotatingPhotoCanvas() {
  const [frameColorIndex, setFrameColorIndex] = useState(0);
  const [scale, setScale] = useState(1);
  const [frameSize, setFrameSize] = useState(1);
  const [imageUrl, setImageUrl] = useState('/payypay.jpg');

  const neonColors = [
    '#ff00ff', // Neon Pink
    '#9400d3', // Neon Purple
    '#39ff14', // Neon Green
    '#00ffff', // Neon Blue
  ];

  const handleFrameColorChange = () => {
    setFrameColorIndex((prevIndex) => (prevIndex + 1) % neonColors.length);
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageUrl(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleFrameSizeChange = (event) => {
    setFrameSize(Number(event.target.value));
  };

  const uploadToImgur = async () => {
    try {
      // Capture the canvas as an image
      const canvas = document.querySelector('canvas');
      if (canvas) {
        const dataURL = canvas.toDataURL('image/png');

        // Prepare form data
        const formData = new FormData();
        formData.append('image', dataURL.split(',')[1]); // Remove data URL prefix

        // Upload to Imgur
        const response = await axios.post('https://api.imgur.com/3/image', formData, {
          headers: {
            'Authorization': 'Client-ID YOUR_IMGUR_CLIENT_ID',
            'Content-Type': 'application/x-www-form-urlencoded',
          }
        });

        const imgurUrl = response.data.data.link;
        window.open(imgurUrl, '_blank'); // Open uploaded image URL in a new tab
      }
    } catch (error) {
      console.error('Error uploading to Imgur:', error);
    }
  };

  const currentFrameColor = neonColors[frameColorIndex];

  const bind = useGesture({
    pinch: (state) => {
      setScale(Math.max(0.5, Math.min(2, scale * state.offset[0])));
    }
  });

  useEffect(() => {
    const handleTouchMove = (e) => {
      if (e.touches.length === 2) {
        const dx = e.touches[1].clientX - e.touches[0].clientX;
        const dy = e.touches[1].clientY - e.touches[0].clientY;
        const distance = Math.sqrt(dx * dx + dy * dy);
        setScale(Math.max(0.5, Math.min(2, distance / 100)));
      }
    };

    window.addEventListener('touchmove', handleTouchMove, { passive: false });

    return () => {
      window.removeEventListener('touchmove', handleTouchMove);
    };
  }, [scale]);

  return (
    <div
      {...bind()}
      style={{
        height: '100vh',
        width: '100vw',
        backgroundColor: '#000000', // Set background to black
        position: 'relative',
        touchAction: 'none',
        overflow: 'hidden',
      }}
    >
      <button
        onClick={handleFrameColorChange}
        className="absolute top-4 left-4 z-10 bg-white text-black py-2 px-4 rounded shadow-md hover:bg-gray-100 focus:outline-none w-32"
      >
        Frame Style
      </button>
      <input
        type="file"
        accept="image/*"
        onChange={handleImageUpload}
        className="absolute top-16 left-4 z-10 bg-white text-black py-2 px-4 rounded shadow-md cursor-pointer w-32"
      />
      <button
        onClick={uploadToImgur}
        className="absolute top-28 left-4 z-10 bg-green-500 text-white py-2 px-4 rounded shadow-md hover:bg-green-600 focus:outline-none w-32"
      >
        imagur
      </button>
      <div className="absolute top-40 left-4 z-10 flex items-center">
        <label htmlFor="frameSize" className="text-white mr-2">Frame Size:</label>
        <input
          id="frameSize"
          type="range"
          min="0.5"
          max="2"
          step="0.1"
          value={frameSize}
          onChange={handleFrameSizeChange}
          className="w-32 bg-white rounded-lg"
        />
        <span className="text-white ml-2">{frameSize.toFixed(1)}</span>
      </div>
      <Canvas style={{ height: '100%', width: '100%', transform: `scale(${frameSize})` }}>
        <ambientLight intensity={0.3} />
        <pointLight color={currentFrameColor} intensity={1} position={[0, 0, 5]} />
        <RotatingPhoto imageUrl={imageUrl} scale={scale} glowColor={currentFrameColor} />
        <OrbitControls
          enableZoom={false}
          minPolarAngle={Math.PI / 2 - Math.PI / 4}
          maxPolarAngle={Math.PI / 2 + Math.PI / 4}
          minAzimuthAngle={-Math.PI / 4}
          maxAzimuthAngle={Math.PI / 4}
        />
      </Canvas>
    </div>
  );
}

